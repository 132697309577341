.custom-tooltip {
  position: relative;
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 5px;
  width: fit-content;
  text-align: center;
  border: solid 2px gray;
}

.custom-tooltip::after {
  content: "";
  position: absolute;
  bottom: -24px; /* Ajusta la distancia del pico al tooltip */
  left: 50%;
  margin-left: -10px;
  border-width: 12px;
  border-style: solid;
  border-color: black transparent transparent transparent; /* Cambio en el orden de los colores para invertir la dirección del pico */
}

.custom-tooltip-label {
  font-weight: bold;
  /* opacity: 0.8; */
}

.custom-tooltip-bar-name {
  margin-right: 5px;
}

.recharts-bar-rectangle:hover {
    stroke-width: 2px;
    /* Establece el ancho del borde */
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.5));
    /* Añade una sombra al pasar el mouse */
}

.white-line {
  background-color: white !important;
  height: 2px !important;
}

.div-green {
  padding-top: 35px;
  padding-bottom: 10px;
  background-color: #43aa44;
}

.chart-title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

.chart-container {
  padding: 20px; 
  background-color: #fff;
/*   border-radius: 8px; 
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.report-select option {
  font-weight: bold;
}

.report-fields {
  padding-top: 60px;
  padding-left: 60px;
}

.row.report-charts {
  padding-left: 60px;
}

.back-button {
/*   background: url('../../../public/images/back.png') no-repeat center center;
  background-color: #f8f9fa;
  background-size: 100% auto;
  width: 64px;
  height: 46px; */
  /* background-image: url('../../../public/images/back.png'); */
  background-image: url('../../../public/images/icon-back.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 50px;
  height: 40px;
  background-color: transparent !important;
  border: none transparent;
}