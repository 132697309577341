/* html,
body {
    height: 100%;
} */

#main-navbar {
    border-bottom: 2px solid #43aa44;
    background: #eeeeee;
}

#main-navbar form input[type="search"] {
    background: #e6e7e8;
    min-width: 200px;
    padding-left: 40px;
    background: transparent url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2019.9%2019.7%22%3E%3Cg%20class%3D%22search-path%22%20fill%3D%22none%22%20stroke%3D%22%236d757d%22%3E%3Cpath%20stroke-linecap%3D%22square%22%20d%3D%22M18.5%2018.3l-5.4-5.4%22%2F%3E%3Ccircle%20cx%3D%228%22%20cy%3D%228%22%20r%3D%227%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E") no-repeat 13px center;
}

#main-navbar .plus-button {
    color: #43aa44;
    font-size: 32px;
    line-height: 1em;
}

/* #main-navbar .custom-export-button {
    background-image: url(../../../public/images/download.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100px;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px; 
} */

.export-button {
/*     background: url('../../../public/images/download.png') no-repeat center center;
    background-color: #f8f9fa;
    background-size: 100% auto;
    width: 94px;
    height: 46px; */
    background-image: url('../../../public/images/download.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 76px;
    height: 46px;
}

.chart-reports-button {
/*     background: url('../../../public/images/charts.png') no-repeat center center;
    background-color: #f8f9fa;
    background-size: 100% auto;
    width: 94px;
    height: 46px; */
    background-image: url('../../../public/images/charts.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 39px;
    height: 46px;
}

.dynamo-reports-button {
    /* background-image: url('../../../public/images/dynamo-report.png'); */
    background-image: url('../../../public/images/dynamo-report-icon.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 76px;
    height: 46px;
}

#main-navbar select, .report-select {
    background: #fff;
    color: #43aa44;
    font-weight: bold;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%2343aa44' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    background-position: calc(100% - 5px) center;
    background-repeat: no-repeat;
    background-size: 15px auto;
}

#main-table {
    position: relative;
}

#main-table thead {
    background: #43aa44;
    color: #fcfffc;
}

#main-table thead th {
    padding-bottom: 15px;
    position: relative;
    text-align: center;;
}


#main-table thead tr th:after {
    content: '-';
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    display: block;
    background-color: #fcfffc;
    color: #43aa44;
    color: transparent;
    line-height: 2px;
}

#main-table td, #main-table th  {
    text-align: center;
    vertical-align: middle;
}

th.running, th.available {
    color: #43aa44;
}

th.stopping, th.stopped, th.shutting-down, th.terminated, th.deleting {
    color: #dc3545;
}

th.pending, th.backing-up, th.starting, th.creating {
    color: #ffc107;
}

button.btn.ssl-VALID, button.btn.ssl-VALID:hover {
    color: white;
    background-color: #43aa44;
    border-color: #43aa44;
}

button.btn.ssl-EXPIRED, button.btn.ssl-EXPIRED:hover {
    color: white;
    background-color: #dc3545;
    border-color: #dc3545;
}

button.btn.ssl-RENEW, button.btn.ssl-RENEW:hover {
    color: white;
    /* background-color: #ff9807fa;
    border-color: #ffc107; */
    background-color: #d3861bfa;
    border: #d3861bfa;
    
}

a.options-table-instances {
    padding: 5px 15px;
    background-size: cover !important;
}

a.options-table-instances.actions-icon {
    background: url(../../../public/images/actions-icon.svg);
    margin-right: 8px;
}

a.options-table-instances.uploads-icon {
    background: url(../../../public/images/cloud-upload.png);
    margin-right: 8px;
}

a.options-table-instances.information-icon {
    background: url(../../../public/images/information-icon.svg);
}

a.options-table-instances.updates-icon {
    background: url(../../../public/images/updates-icon.svg);
    margin-right: 8px;
}

a.options-table-instances.commands-icon {
    background: url(../../../public/images/SystemSettings.svg);
    margin-right: 8px;
}

a.options-table-instances.dbsnapshots-icon {
    background: url(../../../public/images/dbsnapshots-icon.svg);
    margin-right: 8px;
    padding-right: 25px;
}

.modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .5);
    opacity: 1;
    transition: opacity .2s ease;
    z-index: 11;
    overflow: auto;
    padding: 20px;
}

.modal-overlay-second {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .5);
    opacity: 1;
    transition: opacity .2s ease;
    z-index: 12; /* Valor superior al del primer modal */
    overflow: auto;
    padding: 20px;
}

.modal-actions {
    border: 5px solid #43aa44;
    background-color: white;
    border-radius: 30px;
    width: auto;
    max-width: 100%;
    padding: 35px;
    margin: auto;
}

.modal-commands {
    border: 5px solid #43aa44;
    background-color: white;
    border-radius: 30px;
    width: auto;
    /* max-width: 400px; */
    min-width: 500px;
    min-width: 535px;
    padding: 35px;
    margin: auto;
}

.modal-upload, .modal-dynamo {
    border: 5px solid #43aa44;
    background-color: white;
    border-radius: 30px;
    width: min-content;
    max-width: 100%;
    min-width: 600px;
    padding: 35px;
    margin: auto;
}

.modal-dynamo {
    min-width: 670px !important; 
}

button.btn-modal-action {
    margin-bottom: 5px;
    min-width: 100px !important;
}

button.btn-modal-command {
    margin-bottom: 5px;
    min-width: 138px !important;
    white-space: nowrap;
}

.modal-actions-body {
    background-color: #f7f7f7;
    border-radius: 10px;
    width: 100%;
}

.section-title h5, .modal-information h4, .modal-actions.upload-file h4, .modal-actions h4, .modal-upload h4, .modal-commands h4, .modal-dynamo h4, .div-admin-list h5 {
    color: #43aa44;
}

.col-sm-3.custom-margin-right {
    margin-right: 33px;
}

.title-modal {
    padding: 30px 0px 0px 30px;
}

.section-modal {
    padding: 20px 0px 0px 55px;
}

.section-modal-action {
    padding: 20px 20px 10px 55px;
}

.section-modal-lms-action {
    padding: 20px 20px 10px 35px;
}

.section-modal-command {
    /* padding: 20px 105px 0px 55px; */
    /* padding: 20px 0px 0px 55px; */
    padding: 20px 30px 1px 55px;
}

.output-command {
    background-color: white;
    white-space: break-spaces;
    max-height: 150px; /* Ajusta la altura máxima según sea necesario */
    max-width: 385px;
    overflow: auto; /* Agrega scroll cuando el contenido exceda la altura máxima */
    transition: background-color 0.5s ease;
}

.section-output-modal-command {
    padding: 20px 35px 0px 55px;
}

.section-modal-delete {
    padding-bottom: 25px;
}

.form.request-delete .mr {
    margin-right: 20px;
}

.section-content {
    padding: 20px 20px 0px 50px;
}

.section-content-lms-actions {
    padding: 20px 20px 0px 20px;
}

.section-content-final {
    padding-bottom: 25px;
    padding-top: 5px;
}

.section-content-command-update {
    padding: 10px 0px 25px 20px;
}

.section-content-command {
    padding: 20px 0px 0px 20px;
}

.section-content.delete {
    padding: 0px 82px 20px 39px;
}

div#output-board, div#addInstace-output-board {
    /* width: 41%!important; */
    background-color: rgba(67, 170, 69, 0.200);
    white-space: break-spaces;
    /* max-height: 1280px;
    min-height: 840px; */
    overflow-y: auto;
    transition: background-color 0.5s ease;
}

.add-instance-container {
    display: flex;
    min-height: 0; /* Importante para que flex funcione correctamente */
}

div#upgrade-output-board {
    /* width: 41%!important; */
    background-color: rgba(67, 170, 69, 0.200);
    white-space: break-spaces;
    min-height: 985px;
    transition: background-color 0.5s ease;
}

div#rollback-output-board {
    /* width: 41%!important; */
    background-color: rgba(67, 170, 69, 0.200);
    white-space: break-spaces;
    min-height: 895px;
    transition: background-color 0.5s ease;
}

h2#delete-resources-title, h2#add-instance-title {
    color: #43aa44;
}

.field-error {
    color: red;
}

div#add-instance-form h3, div#delete-instance-form h3, div.delete-details-container h3 {
    font-size: 1.50rem !important;
}

.form-check.m-edumy {
    margin-left: 10px;
}

form.add-form label, form.request-delete label, form.instance-data label {
    font-weight: 500;
}

.delete-form-container label {
    font-weight: 500;
}

.add-instance-container {
    margin-left: 6px;
}

.errorMessage {
    margin: 15px;
    font-weight: 500;
}

/* .modal-actions.upload-file {
    height: 775px !important;
} */

/* .title-modal.upload-file {
    padding: 30px 0px 0px 20px;
} */
.title-modal-upload, .title-modal-actions {
    padding: 0px 0px 10px 30px;
    display: inline-block;
}

.title-modal-lms-actions {
    padding: 10px 0px 10px 0px;
    display: inline-block;
}

.section-modal.upload-file {
    padding: 20px 0px 30px 20px;
}

.section-content.upload-file {
    padding: 10px 20px 0px 0px;
}

.text-area-description {
    width: 100%;
}

#upload-file-section select {
    color: #43aa44;
    background: white;
    font-weight: 600;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%2343aa44' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    background-position: calc(100% - 5px) center;
    background-repeat: no-repeat;
    background-size: 15px auto;
    border-radius: 5px;
    border: 1px solid #43aa44;
}

#upload-file-section select option {
    font-weight: 500;
}

.pathMessage {
    margin: 15px 15px 0px 0px;
    font-weight: 500;
}

.scrollit {
    max-height: 250px;
    overflow: auto;
    width: 98%;
}

.scrollit-dbsnap {
    max-height: 350px;
    overflow: auto;
    width: 98%;
}

.scrollit-dblist {
    max-height: 544px;
    overflow: auto;
    width: 98%;
}

.scrollit-eventlist {
    max-height: 750px;
    overflow: auto;
    width: 98%;
}

.drop-file-input {
    position: relative;
    width: 100%;
    height: 200px;
    border: 2px dashed #43aa44;
    border-radius: 20px;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #d1e7dd;
}

.drop-file-input input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.drop-file-input-label {
    text-align: center;
    color: gray;
    font-weight: 600;
    padding: 10px;
}

.drop-file-input-label img {
    width: 100px;
}

.drop-file-input:hover,
.drop-file-input.dragover {
    opacity: 0.6;
}

.not-upload-allowed {
    background-color: #dddddd;
    border: 2px dashed gray;
}

.not-enabled {
    opacity: 0.1;
}

.select-empty {
    border: 2px solid #dc3545 !important;
}

.modal-information {
    border: 5px solid #43aa44;
    background-color: white;
    border-radius: 30px;
    width: auto;
    padding: 35px;
    margin: auto;
    max-width: 100%;
}

button.close-modal-button {
    border-radius: 5px;
    border: 1px solid;
    border-color: #43aa44;
    color: #43aa44;
    float: right;
    font-weight: 700;
    margin: -20px -20px;
    background-color: white;
    font-size: 14px;
    padding-bottom: 3px;
}

.title-modal-information {
    padding: 0px 0px 10px 30px;
}

.section-content.volumes {
    padding: 20px 20px 10px 10px;
}

.section-content.report {
    padding: 20px 20px 10px 0px;
}

.section-content.domains {
    padding: 20px 0px 10px 0px;
}

.section-content.databases {
    padding: 10px 0px 20px 0px;
}

table#volumes-table, 
table#volumes-table td, 
table#volumes-table th,
table#dbsnap-table,
table#dbsnap-table td, 
table#dbsnap-table th,
table#events-table,
table#events-table td, 
table#events-table th,
table#admin-table,
table#admin-table td, 
table#admin-table th,
table#db-table,
table#db-table td, 
table#db-table th,
table#volumes-table select {
    border: 4px solid #AED6AD;
    text-align: center;
    font-weight: normal;
    color: #737373;
}

table#volumes-table td:last-child {
    text-align: justify;
}

table.table.table-dbinstances th:last-child, 
table.table.table-dbinstances td:last-child {
    text-align: left !important;
}

table#volumes-table th, table#dbsnap-table th, table#db-table th, table#events-table th, table#admin-table th {
    color: white;
    background-color: #43aa44;
}

table#files-table, table#plugins-table{
    margin-bottom: 0px;
}

img.ssl-status {
    width: 30px;
    height: 30px;
}
img.backup-volume, img.download-icon {
    width: 22px;
    height: 22px;
}

button.btn.btn-resize {
    border: 2px solid #43aa44;
    border-radius: inherit;
    padding: inherit;
    color: #737373;
}

button.btn.btn-resize:hover {
    background-color: #AED6AD;
}

/* form.instance-data input {
    margin: 15px 0px 0px 67px;
} */

.col-sm-4.margin-left {
    margin-left: 250px;
}

button.btn.btn-instance-data {
    /* margin-right: -70px !important;
    margin-top: 30px; */
    color: white;
    background-color: #43aa44;
    border-radius: 15px;
    padding: 5px 23px;
}

button.btn.btn-instance-data:hover {
    color: white;
    background-color: #43aa44;
}

.col-sm-4.margin-right {
    margin-right: 70px;
}

hr.divisor-modal {
    margin: 15px 22px 0px 22px !important;
}

hr.divisor-modal-action, .divisor-modal-command {
    margin: 0px 22px 0px 22px !important;
}

span.ssl-icon {
    display: block;
}

span.no-ssl, button.no-ssl, td.not-elastic-ip, td.out-of-support{
    color: #dc3545;
    font-weight: 700;
}

.section-modal-rds {
    padding: 20px 30px 40px 55px;
}

.section-modal-dbsnap {
    padding: 20px 20px 0px 55px;
}

.modal-actions.information-dbsnap {
    width: 740px !important;
}

.modal-actions.information-databases {
    width: 555px !important;
    max-height: 765px;
}

.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
    background-color: #e4f3ec !important;
}

button.btn.btn-custom {
    color: white;
    background-color: #43aa44;
}

button.btn.btn-custom:hover {
    color: white;
    background-color: #43aa44;
}

.btn-main {
    border-radius: 15px;
    padding: 5px 23px;
}

.loading {
    border: 6px solid #d4d4d4;
    border-top: 6px solid #43aa44;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1.5s linear infinite;
    position: fixed;
    /* cambiamos de fixed a absolute */
    /* Ponemos el valor de left, bottom, right y top en 0 */
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: auto;
    z-index: 50000;
    /* Centramos vertical y horizontalmente */
}

.loading.auth {
    border-top: 6px solid #fff;
}

.center-td select {
    margin: 0 auto;
    display: block;
    width: 50% !important;
    /* text-align: center; */
  }

.col-sm-4.upgrade-key {
    margin-left: 70px;
}

span.material-symbols-outlined.vol-info, span.material-symbols-outlined.vol-action, span.material-symbols-outlined.vol-upgrade {
    color: #43aa44;
    vertical-align: -6px;
}

.read-only {
    background-color: #f2f2f2;
}

.report-select {
    box-shadow: 0.5px 0.5px 4px rgba(0, 0, 0, 0.2);
}

a.dropdown-item.option-custom {
    background-color: #43aa44 !important;
    border: 2px solid white;
    color: white;
    text-align: center;
    text-decoration: none;
    border-radius: 0.5rem;
    font-weight: 400;
}

.dropdown-menu.dropdown-custom {
    background-color: transparent;
    border: none;
}

@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
}

@media (min-width: 576px) { /* Aplica el padding solo en pantallas más grandes que el breakpoint más pequeño */
    .row.text-end.mr.div-delete-instance {
      padding-right: calc((100% - 19rem) / 2); /* Calcula el padding dinámicamente */
      /* Ajusta según el ancho total que deseas mantener para el botón y el espacio entre el botón y el input */
    }
  }

.section-action-content {
    /* padding: 10px 20px 0px 5px; */
    padding: 10px 20px 0px 15px;
}

.label-reason {
    font-weight: 500;
}

/* textarea.form-control.is-invalid {
    border: 3px solid #BB0C19;
} */

input.form-control.wrong, textarea.form-control.wrong {
    border: 1px solid #BB0C19 !important;
}

.font-weight-500 {
    font-weight: 500;
}

.output-scroll {
    overflow-y: auto;
    height: 850px;
}

.addInstace-output-scroll {
    overflow-y: auto;
    height: 825px;
}

.error-border {
    border: 1px solid red !important;
    border-radius: 5px;
    padding: 5px;
}

.logo-content button {
    width: 160px;
    background-color: rgba(67, 170, 69, 0.200) !important;
    color: rgba(10, 10, 10, 0.5) !important;
    border: none !important;
}

.creation-buttons button {
    width: 160px;
}

.content-form-addintstance {
    min-width: 420px;
}

.modal-actions.information-events {
    min-width: 920px;
}

.download-button {
    background-image: url("../../../public/images/download-white.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 25px;
    height: 19px;
    border: none;
}

button.download-button:hover {
    background-color: transparent;
}

/* dashboard admin */
.px-custom {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
}

/* a.options-table-instances.edit-user-icon {
    background: url(../../../public/images/edit.png);
    margin-right: 8px;
}

a.options-table-instances.reset-password-icon {
    background: url(../../../public/images/reset-pass.png);
    margin-right: 8px;
} */

.options-table-admin {
    display: inline-block;
    width: 34px;
    height: 34px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.edit-icon {
    background-image: url(../../../public/images/edit.png);
}

.reset-password-icon {
    background-image: url(../../../public/images/reset-pass.png);
}

.delete-icon {
    background-image: url(../../../public/images/delete.png);
}

.add-icon {
    background-image: url(../../../public/images/add.png);
}

.user-icon-dimensions {
    width: 24px;
    height: 24px;
}

.action-item {
    min-width: 34px;
    display: inline-block;
}

.bg-custom-gray {
    background-color: #f7f7f7;
}

.admin-button {
    background-image: url('../../../public/images/config.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 39px;
    height: 46px;
}

.section-content.admin {
    padding: 0px 20px 10px 0px;
}

.modal-actions.admin {
    min-width: 620px;
}

form.form-user label {
    font-weight: 500;
    color: #737373;
    margin-bottom: 2px;
}

form.form-user select {
    background: white;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%2343aa44' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    background-position: calc(100% - 5px) center;
    background-repeat: no-repeat;
    background-size: 15px auto;
    border-radius: 5px;
}

button.options-table-admin.delete-icon, button.options-table-admin.edit-icon {
    border: none;
    background-color: transparent;
}

.table-container {
    max-height: 450px;
    overflow-y: auto;
}

/* Para mantener el encabezado fijo mientras se hace scroll */
.table-container thead th {
    position: sticky;
    /* Ajustar el top para que coincida con el borde superior de la tabla */
    top: -4px;
    background-color: #43aa44 !important;
    z-index: 1;
    /* Añadir estas propiedades */
    border-left: 4px solid #AED6AD !important;
    border-right: 4px solid #AED6AD !important;
}

/* Para el primer th del header */
.table-container thead th:first-child {
    border-left: 4px solid #AED6AD !important;
}

/* Para el último th del header */
.table-container thead th:last-child {
    border-right: 4px solid #AED6AD !important;
}

.modal-form-container {
    width: 100%;
}

#isPluginCheck:checked {
    background-color: #43aa44;
    border-color: #43aa44;
}
