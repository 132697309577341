html,
body {
    height: 100%;    
}

.login {
    display: grid;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background: rgb(74, 170, 71);
    background: url('../../../public/images/login-background.png') no-repeat bottom right fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    text-align: center;
}

.form-signin, .form-qrcode {
    max-width: 350px;
    padding: 15px;
    color: #fff;
}

.form-signin button[type="submit"], .form-qrcode button[type="submit"] {
    background: #ffc600;
    color: #685927;
    border: 1px solid #685927;
    font-weight: 500;
}

.form-signin button[type="submit"]:hover {
    background: #685927;
    color: #ffc600;
}

input.form-control.wrong {
    border: 3px solid #BB0C19;
}